/**
 * https://www.keycloak.org/docs-api/11.0/rest-api/index.html#_requiredactionproviderrepresentation
 */
export var RequiredActionAlias;
(function (RequiredActionAlias) {
  RequiredActionAlias["VERIFY_EMAIL"] = "VERIFY_EMAIL";
  RequiredActionAlias["UPDATE_PROFILE"] = "UPDATE_PROFILE";
  RequiredActionAlias["CONFIGURE_TOTP"] = "CONFIGURE_TOTP";
  RequiredActionAlias["UPDATE_PASSWORD"] = "UPDATE_PASSWORD";
  RequiredActionAlias["TERMS_AND_CONDITIONS"] = "TERMS_AND_CONDITIONS";
})(RequiredActionAlias || (RequiredActionAlias = {}));