import { NgIf, NgIfContext } from '@angular/common';
import { computed, Directive, effect, inject, input, TemplateRef } from '@angular/core';
import { AuthStore } from './auth.store';

@Directive({
  selector: '[libHasRole], [libHasRoleIsSuperAdmin]',
  standalone: true,
  hostDirectives: [NgIf],
})
export class HasRoleDirective {
  libHasRole = input.required<string | string[]>();
  libHasRoleElse = input<TemplateRef<NgIfContext<unknown>>>();
  libHasRoleIsSuperAdmin = input<boolean>(false);

  #store = inject(AuthStore);
  #ngIf = inject(NgIf, { host: true });

  private readonly showTemplate = computed(() => {
    if (!this.#store.isLoggedIn()) {
      return false;
    }

    if (this.libHasRoleIsSuperAdmin()) {
      return this.#store.isSuperAdmin();
    }

    return !!this.#store.hasAnyRole(this.libHasRole());
  });

  constructor() {
    effect(() => {
      this.#ngIf.ngIf = this.showTemplate();
      if (this.libHasRoleElse()) {
        this.#ngIf.ngIfElse = this.libHasRoleElse()!;
      }
    });
  }
}
