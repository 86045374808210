import Resource from "./resource.js";
export class ClientScopes extends Resource {
  find = this.makeRequest({
    method: "GET",
    path: "/client-scopes"
  });
  create = this.makeRequest({
    method: "POST",
    path: "/client-scopes",
    returnResourceIdInLocationHeader: {
      field: "id"
    }
  });
  /**
   * Client-Scopes by id
   */
  findOne = this.makeRequest({
    method: "GET",
    path: "/client-scopes/{id}",
    urlParamKeys: ["id"],
    catchNotFound: true
  });
  update = this.makeUpdateRequest({
    method: "PUT",
    path: "/client-scopes/{id}",
    urlParamKeys: ["id"]
  });
  del = this.makeRequest({
    method: "DELETE",
    path: "/client-scopes/{id}",
    urlParamKeys: ["id"]
  });
  /**
   * Default Client-Scopes
   */
  listDefaultClientScopes = this.makeRequest({
    method: "GET",
    path: "/default-default-client-scopes"
  });
  addDefaultClientScope = this.makeRequest({
    method: "PUT",
    path: "/default-default-client-scopes/{id}",
    urlParamKeys: ["id"]
  });
  delDefaultClientScope = this.makeRequest({
    method: "DELETE",
    path: "/default-default-client-scopes/{id}",
    urlParamKeys: ["id"]
  });
  /**
   * Default Optional Client-Scopes
   */
  listDefaultOptionalClientScopes = this.makeRequest({
    method: "GET",
    path: "/default-optional-client-scopes"
  });
  addDefaultOptionalClientScope = this.makeRequest({
    method: "PUT",
    path: "/default-optional-client-scopes/{id}",
    urlParamKeys: ["id"]
  });
  delDefaultOptionalClientScope = this.makeRequest({
    method: "DELETE",
    path: "/default-optional-client-scopes/{id}",
    urlParamKeys: ["id"]
  });
  /**
   * Protocol Mappers
   */
  addMultipleProtocolMappers = this.makeUpdateRequest({
    method: "POST",
    path: "/client-scopes/{id}/protocol-mappers/add-models",
    urlParamKeys: ["id"]
  });
  addProtocolMapper = this.makeUpdateRequest({
    method: "POST",
    path: "/client-scopes/{id}/protocol-mappers/models",
    urlParamKeys: ["id"]
  });
  listProtocolMappers = this.makeRequest({
    method: "GET",
    path: "/client-scopes/{id}/protocol-mappers/models",
    urlParamKeys: ["id"]
  });
  findProtocolMapper = this.makeRequest({
    method: "GET",
    path: "/client-scopes/{id}/protocol-mappers/models/{mapperId}",
    urlParamKeys: ["id", "mapperId"],
    catchNotFound: true
  });
  findProtocolMappersByProtocol = this.makeRequest({
    method: "GET",
    path: "/client-scopes/{id}/protocol-mappers/protocol/{protocol}",
    urlParamKeys: ["id", "protocol"],
    catchNotFound: true
  });
  updateProtocolMapper = this.makeUpdateRequest({
    method: "PUT",
    path: "/client-scopes/{id}/protocol-mappers/models/{mapperId}",
    urlParamKeys: ["id", "mapperId"]
  });
  delProtocolMapper = this.makeRequest({
    method: "DELETE",
    path: "/client-scopes/{id}/protocol-mappers/models/{mapperId}",
    urlParamKeys: ["id", "mapperId"]
  });
  /**
   * Scope Mappings
   */
  listScopeMappings = this.makeRequest({
    method: "GET",
    path: "/client-scopes/{id}/scope-mappings",
    urlParamKeys: ["id"]
  });
  addClientScopeMappings = this.makeUpdateRequest({
    method: "POST",
    path: "/client-scopes/{id}/scope-mappings/clients/{client}",
    urlParamKeys: ["id", "client"]
  });
  listClientScopeMappings = this.makeRequest({
    method: "GET",
    path: "/client-scopes/{id}/scope-mappings/clients/{client}",
    urlParamKeys: ["id", "client"]
  });
  listAvailableClientScopeMappings = this.makeRequest({
    method: "GET",
    path: "/client-scopes/{id}/scope-mappings/clients/{client}/available",
    urlParamKeys: ["id", "client"]
  });
  listCompositeClientScopeMappings = this.makeRequest({
    method: "GET",
    path: "/client-scopes/{id}/scope-mappings/clients/{client}/composite",
    urlParamKeys: ["id", "client"]
  });
  delClientScopeMappings = this.makeUpdateRequest({
    method: "DELETE",
    path: "/client-scopes/{id}/scope-mappings/clients/{client}",
    urlParamKeys: ["id", "client"]
  });
  addRealmScopeMappings = this.makeUpdateRequest({
    method: "POST",
    path: "/client-scopes/{id}/scope-mappings/realm",
    urlParamKeys: ["id"]
  });
  listRealmScopeMappings = this.makeRequest({
    method: "GET",
    path: "/client-scopes/{id}/scope-mappings/realm",
    urlParamKeys: ["id"]
  });
  listAvailableRealmScopeMappings = this.makeRequest({
    method: "GET",
    path: "/client-scopes/{id}/scope-mappings/realm/available",
    urlParamKeys: ["id"]
  });
  listCompositeRealmScopeMappings = this.makeRequest({
    method: "GET",
    path: "/client-scopes/{id}/scope-mappings/realm/composite",
    urlParamKeys: ["id"]
  });
  delRealmScopeMappings = this.makeUpdateRequest({
    method: "DELETE",
    path: "/client-scopes/{id}/scope-mappings/realm",
    urlParamKeys: ["id"]
  });
  constructor(client) {
    super(client, {
      path: "/admin/realms/{realm}",
      getUrlParams: () => ({
        realm: client.realmName
      }),
      getBaseUrl: () => client.baseUrl
    });
  }
  /**
   * Find client scope by name.
   */
  async findOneByName(payload) {
    const allScopes = await this.find({
      ...(payload.realm ? {
        realm: payload.realm
      } : {})
    });
    return allScopes.find(item => item.name === payload.name);
  }
  /**
   * Delete client scope by name.
   */
  async delByName(payload) {
    const scope = await this.findOneByName(payload);
    if (!scope) {
      throw new Error("Scope not found.");
    }
    await this.del({
      ...(payload.realm ? {
        realm: payload.realm
      } : {}),
      id: scope.id
    });
  }
  /**
   * Find single protocol mapper by name.
   */
  async findProtocolMapperByName(payload) {
    const allProtocolMappers = await this.listProtocolMappers({
      id: payload.id,
      ...(payload.realm ? {
        realm: payload.realm
      } : {})
    });
    return allProtocolMappers.find(mapper => mapper.name === payload.name);
  }
}