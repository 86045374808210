import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { combineLatest, filter, from, merge, of, switchMap, tap } from 'rxjs';
import { AuthStore } from './auth.store';
import { KeycloakAdminService } from './keycloak-admin.service';

@Injectable({ providedIn: 'root' })
export class KeycloakUserConnectService {
  #keycloak = inject(KeycloakService);
  #admin = inject(KeycloakAdminService);
  #userStore = inject(AuthStore);

  init() {
    const refreshToken$ = this.#keycloak.keycloakEvents$.asObservable().pipe(
      filter(({ type }) => type === KeycloakEventType.OnTokenExpired),
      tap(() => this.#keycloak.updateToken(20)),
      switchMap(() => from(this.#keycloak.getToken())),
      tap(token => this.#admin.client.setAccessToken(token))
    );

    const updateUserIfLogin$ = of(this.#keycloak.isLoggedIn()).pipe(
      filter(Boolean),
      switchMap(() =>
        combineLatest([this.#keycloak.loadUserProfile(), of(this.#keycloak.getUserRoles(true) as string[])])
      ),
      tap(([profile, roles]) => {
        this.#userStore.loadUser({ profile, roles });
      })
    );

    merge(refreshToken$, updateUserIfLogin$).pipe(takeUntilDestroyed()).subscribe();
  }
}
