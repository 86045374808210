import Resource from "./resource.js";
export class Users extends Resource {
  find = this.makeRequest({
    method: "GET"
  });
  create = this.makeRequest({
    method: "POST",
    returnResourceIdInLocationHeader: {
      field: "id"
    }
  });
  /**
   * Single user
   */
  findOne = this.makeRequest({
    method: "GET",
    path: "/{id}",
    urlParamKeys: ["id"],
    catchNotFound: true
  });
  update = this.makeUpdateRequest({
    method: "PUT",
    path: "/{id}",
    urlParamKeys: ["id"]
  });
  del = this.makeRequest({
    method: "DELETE",
    path: "/{id}",
    urlParamKeys: ["id"]
  });
  count = this.makeRequest({
    method: "GET",
    path: "/count"
  });
  getProfile = this.makeRequest({
    method: "GET",
    path: "/profile"
  });
  updateProfile = this.makeRequest({
    method: "PUT",
    path: "/profile"
  });
  getProfileMetadata = this.makeRequest({
    method: "GET",
    path: "/profile/metadata"
  });
  /**
   * role mappings
   */
  listRoleMappings = this.makeRequest({
    method: "GET",
    path: "/{id}/role-mappings",
    urlParamKeys: ["id"]
  });
  addRealmRoleMappings = this.makeRequest({
    method: "POST",
    path: "/{id}/role-mappings/realm",
    urlParamKeys: ["id"],
    payloadKey: "roles"
  });
  listRealmRoleMappings = this.makeRequest({
    method: "GET",
    path: "/{id}/role-mappings/realm",
    urlParamKeys: ["id"]
  });
  delRealmRoleMappings = this.makeRequest({
    method: "DELETE",
    path: "/{id}/role-mappings/realm",
    urlParamKeys: ["id"],
    payloadKey: "roles"
  });
  listAvailableRealmRoleMappings = this.makeRequest({
    method: "GET",
    path: "/{id}/role-mappings/realm/available",
    urlParamKeys: ["id"]
  });
  // Get effective realm-level role mappings This will recurse all composite roles to get the result.
  listCompositeRealmRoleMappings = this.makeRequest({
    method: "GET",
    path: "/{id}/role-mappings/realm/composite",
    urlParamKeys: ["id"]
  });
  /**
   * Client role mappings
   * https://www.keycloak.org/docs-api/11.0/rest-api/#_client_role_mappings_resource
   */
  listClientRoleMappings = this.makeRequest({
    method: "GET",
    path: "/{id}/role-mappings/clients/{clientUniqueId}",
    urlParamKeys: ["id", "clientUniqueId"]
  });
  addClientRoleMappings = this.makeRequest({
    method: "POST",
    path: "/{id}/role-mappings/clients/{clientUniqueId}",
    urlParamKeys: ["id", "clientUniqueId"],
    payloadKey: "roles"
  });
  delClientRoleMappings = this.makeRequest({
    method: "DELETE",
    path: "/{id}/role-mappings/clients/{clientUniqueId}",
    urlParamKeys: ["id", "clientUniqueId"],
    payloadKey: "roles"
  });
  listAvailableClientRoleMappings = this.makeRequest({
    method: "GET",
    path: "/{id}/role-mappings/clients/{clientUniqueId}/available",
    urlParamKeys: ["id", "clientUniqueId"]
  });
  listCompositeClientRoleMappings = this.makeRequest({
    method: "GET",
    path: "/{id}/role-mappings/clients/{clientUniqueId}/composite",
    urlParamKeys: ["id", "clientUniqueId"]
  });
  /**
   * Send a update account email to the user
   * an email contains a link the user can click to perform a set of required actions.
   */
  executeActionsEmail = this.makeRequest({
    method: "PUT",
    path: "/{id}/execute-actions-email",
    urlParamKeys: ["id"],
    payloadKey: "actions",
    queryParamKeys: ["lifespan", "redirectUri", "clientId"],
    keyTransform: {
      clientId: "client_id",
      redirectUri: "redirect_uri"
    }
  });
  /**
   * Group
   */
  listGroups = this.makeRequest({
    method: "GET",
    path: "/{id}/groups",
    urlParamKeys: ["id"]
  });
  addToGroup = this.makeRequest({
    method: "PUT",
    path: "/{id}/groups/{groupId}",
    urlParamKeys: ["id", "groupId"]
  });
  delFromGroup = this.makeRequest({
    method: "DELETE",
    path: "/{id}/groups/{groupId}",
    urlParamKeys: ["id", "groupId"]
  });
  countGroups = this.makeRequest({
    method: "GET",
    path: "/{id}/groups/count",
    urlParamKeys: ["id"]
  });
  /**
   * Federated Identity
   */
  listFederatedIdentities = this.makeRequest({
    method: "GET",
    path: "/{id}/federated-identity",
    urlParamKeys: ["id"]
  });
  addToFederatedIdentity = this.makeRequest({
    method: "POST",
    path: "/{id}/federated-identity/{federatedIdentityId}",
    urlParamKeys: ["id", "federatedIdentityId"],
    payloadKey: "federatedIdentity"
  });
  delFromFederatedIdentity = this.makeRequest({
    method: "DELETE",
    path: "/{id}/federated-identity/{federatedIdentityId}",
    urlParamKeys: ["id", "federatedIdentityId"]
  });
  /**
   * remove totp
   */
  removeTotp = this.makeRequest({
    method: "PUT",
    path: "/{id}/remove-totp",
    urlParamKeys: ["id"]
  });
  /**
   * reset password
   */
  resetPassword = this.makeRequest({
    method: "PUT",
    path: "/{id}/reset-password",
    urlParamKeys: ["id"],
    payloadKey: "credential"
  });
  getUserStorageCredentialTypes = this.makeRequest({
    method: "GET",
    path: "/{id}/configured-user-storage-credential-types",
    urlParamKeys: ["id"]
  });
  /**
   * get user credentials
   */
  getCredentials = this.makeRequest({
    method: "GET",
    path: "/{id}/credentials",
    urlParamKeys: ["id"]
  });
  /**
   * delete user credentials
   */
  deleteCredential = this.makeRequest({
    method: "DELETE",
    path: "/{id}/credentials/{credentialId}",
    urlParamKeys: ["id", "credentialId"]
  });
  /**
   * update a credential label for a user
   */
  updateCredentialLabel = this.makeUpdateRequest({
    method: "PUT",
    path: "/{id}/credentials/{credentialId}/userLabel",
    urlParamKeys: ["id", "credentialId"],
    headers: {
      "content-type": "text/plain"
    }
  });
  // Move a credential to a position behind another credential
  moveCredentialPositionDown = this.makeRequest({
    method: "POST",
    path: "/{id}/credentials/{credentialId}/moveAfter/{newPreviousCredentialId}",
    urlParamKeys: ["id", "credentialId", "newPreviousCredentialId"]
  });
  // Move a credential to a first position in the credentials list of the user
  moveCredentialPositionUp = this.makeRequest({
    method: "POST",
    path: "/{id}/credentials/{credentialId}/moveToFirst",
    urlParamKeys: ["id", "credentialId"]
  });
  /**
   * send verify email
   */
  sendVerifyEmail = this.makeRequest({
    method: "PUT",
    path: "/{id}/send-verify-email",
    urlParamKeys: ["id"],
    queryParamKeys: ["clientId", "redirectUri"],
    keyTransform: {
      clientId: "client_id",
      redirectUri: "redirect_uri"
    }
  });
  /**
   * list user sessions
   */
  listSessions = this.makeRequest({
    method: "GET",
    path: "/{id}/sessions",
    urlParamKeys: ["id"]
  });
  /**
   * list offline sessions associated with the user and client
   */
  listOfflineSessions = this.makeRequest({
    method: "GET",
    path: "/{id}/offline-sessions/{clientId}",
    urlParamKeys: ["id", "clientId"]
  });
  /**
   * logout user from all sessions
   */
  logout = this.makeRequest({
    method: "POST",
    path: "/{id}/logout",
    urlParamKeys: ["id"]
  });
  /**
   * list consents granted by the user
   */
  listConsents = this.makeRequest({
    method: "GET",
    path: "/{id}/consents",
    urlParamKeys: ["id"]
  });
  impersonation = this.makeUpdateRequest({
    method: "POST",
    path: "/{id}/impersonation",
    urlParamKeys: ["id"]
  });
  /**
   * revoke consent and offline tokens for particular client from user
   */
  revokeConsent = this.makeRequest({
    method: "DELETE",
    path: "/{id}/consents/{clientId}",
    urlParamKeys: ["id", "clientId"]
  });
  getUnmanagedAttributes = this.makeRequest({
    method: "GET",
    path: "/{id}/unmanagedAttributes",
    urlParamKeys: ["id"]
  });
  constructor(client) {
    super(client, {
      path: "/admin/realms/{realm}/users",
      getUrlParams: () => ({
        realm: client.realmName
      }),
      getBaseUrl: () => client.baseUrl
    });
  }
}