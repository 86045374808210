import Resource from "./resource.js";
export class Cache extends Resource {
  clearUserCache = this.makeRequest({
    method: "POST",
    path: "/clear-user-cache"
  });
  constructor(client) {
    super(client, {
      path: "/admin/realms/{realm}",
      getUrlParams: () => ({
        realm: client.realmName
      }),
      getBaseUrl: () => client.baseUrl
    });
  }
}