import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed, inject } from '@angular/core';
import UserRepresentation from '@keycloak/keycloak-admin-client/lib/defs/userRepresentation';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { HotToastService } from '@ngxpert/hot-toast';
import { KeycloakProfile } from 'keycloak-js';
import { KeycloakAdminService } from './keycloak-admin.service';

export interface AuthState {
  isSuperAdmin: boolean;
  roles: string[];
  profile: KeycloakProfile | null;
  loading: boolean;
  customerId: string;
}

const initialAuthState: AuthState = {
  isSuperAdmin: false,
  roles: [],
  profile: null,
  loading: false,
  customerId: '',
};

export const AuthStore = signalStore(
  { providedIn: 'root' },
  withDevtools('auth'),
  withState(initialAuthState),
  withMethods(store => {
    const keycloakAdminClient = inject(KeycloakAdminService).client;
    const toast = inject(HotToastService);

    return {
      loadUser: async (payload: { profile: KeycloakProfile; roles: string[] }) => {
        const { profile, roles } = payload;
        patchState(store, {
          profile,
          roles,
          customerId: profile.attributes?.['customer_id'] as string,
          isSuperAdmin: !!roles?.includes('SUPERADMIN'),
        });
      },
      updateProfile: async (id: string, profile: UserRepresentation) => {
        patchState(store, { loading: true });
        try {
          await keycloakAdminClient.users.update({ id }, profile);

          patchState(store, { profile });
          toast.success('Profile updated');
        } catch (e) {
          toast.error('Update profile error');
          throw e;
        }
        patchState(store, { loading: false });
      },
      resetPassword: async (id: string, password: string) => {
        patchState(store, { loading: true });
        try {
          await keycloakAdminClient.users.resetPassword({ id, credential: { value: password } });
          toast.success('Password updated');
        } catch (e) {
          toast.error('Password reset error');
          throw e;
        }
        patchState(store, { loading: false });
      },
      hasAnyRole: (requiredRoles: string | string[]) => {
        if (store.isSuperAdmin()) return true;

        const rolesToCheck = Array.isArray(requiredRoles) ? requiredRoles : [requiredRoles];
        return !!rolesToCheck?.some(role => store.roles().includes(role));
      },
    };
  }),
  withComputed(store => {
    return {
      isLoggedIn: computed(() => !!store.profile()),
      isSuperAdmin: computed(() => store.isSuperAdmin()),
      fullname: computed(() => `${store.profile()?.firstName} ${store.profile()?.lastName || ''}`),
      email: computed(() => store.profile()?.email),
      id: computed(() => store.profile()?.id),
      customerId: computed(() => store.customerId()),
      idUser: computed(() => store.profile()?.id),
    };
  })
);
