import { APP_INITIALIZER, EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { combineEnvironmentProviders } from '@wheere-front/shared-util';
import { KeycloakAngularModule, KeycloakOptions, KeycloakService } from 'keycloak-angular';
import { KeycloakAdminService } from './keycloak-admin.service';

function initializeKeycloak(keycloak: KeycloakService, admin: KeycloakAdminService, options: KeycloakOptions) {
  return async () => {
    await keycloak.init(options);
    await admin.init(keycloak);
  };
}

const keycloakInitializer = (options: KeycloakOptions) => {
  return {
    provide: APP_INITIALIZER,
    useFactory: (keycloak: KeycloakService, admin: KeycloakAdminService) =>
      initializeKeycloak(keycloak, admin, options),
    multi: true,
    deps: [KeycloakService, KeycloakAdminService],
  };
};

export function provideKeycloak(options: KeycloakOptions): EnvironmentProviders {
  return combineEnvironmentProviders([
    importProvidersFrom([KeycloakAngularModule]),
    keycloakInitializer(options) as unknown as EnvironmentProviders,
  ]);
}
