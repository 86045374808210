import { inject, Injectable } from '@angular/core';
import KcAdminClient from '@keycloak/keycloak-admin-client';
import { APP_ENVIRONMENT_KEYCLOAK } from '@wheere-front/shared-util';
import { KeycloakService } from 'keycloak-angular';

@Injectable({ providedIn: 'root' })
export class KeycloakAdminService {
  keycloakEnv = inject(APP_ENVIRONMENT_KEYCLOAK);
  keycloakService = inject(KeycloakService);

  client!: KcAdminClient;

  async init(keycloakService: KeycloakService) {
    this.client = new KcAdminClient({
      baseUrl: this.keycloakEnv.issuer,
      realmName: this.keycloakEnv.realm,
    });

    const token = await keycloakService.getToken();
    this.client.setAccessToken(token);
  }
}
