import Resource from "./resource.js";
export class AttackDetection extends Resource {
  findOne = this.makeRequest({
    method: "GET",
    path: "/users/{id}",
    urlParamKeys: ["id"],
    catchNotFound: true
  });
  del = this.makeRequest({
    method: "DELETE",
    path: "/users/{id}",
    urlParamKeys: ["id"]
  });
  delAll = this.makeRequest({
    method: "DELETE",
    path: "/users"
  });
  constructor(client) {
    super(client, {
      path: "/admin/realms/{realm}/attack-detection/brute-force",
      getUrlParams: () => ({
        realm: client.realmName
      }),
      getBaseUrl: () => client.baseUrl
    });
  }
}