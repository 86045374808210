import Resource from "./resource.js";
export class Clients extends Resource {
  find = this.makeRequest({
    method: "GET"
  });
  create = this.makeRequest({
    method: "POST",
    returnResourceIdInLocationHeader: {
      field: "id"
    }
  });
  /**
   * Single client
   */
  findOne = this.makeRequest({
    method: "GET",
    path: "/{id}",
    urlParamKeys: ["id"],
    catchNotFound: true
  });
  update = this.makeUpdateRequest({
    method: "PUT",
    path: "/{id}",
    urlParamKeys: ["id"]
  });
  del = this.makeRequest({
    method: "DELETE",
    path: "/{id}",
    urlParamKeys: ["id"]
  });
  /**
   * Client roles
   */
  createRole = this.makeRequest({
    method: "POST",
    path: "/{id}/roles",
    urlParamKeys: ["id"],
    returnResourceIdInLocationHeader: {
      field: "roleName"
    }
  });
  listRoles = this.makeRequest({
    method: "GET",
    path: "/{id}/roles",
    urlParamKeys: ["id"]
  });
  findRole = this.makeRequest({
    method: "GET",
    path: "/{id}/roles/{roleName}",
    urlParamKeys: ["id", "roleName"],
    catchNotFound: true
  });
  updateRole = this.makeUpdateRequest({
    method: "PUT",
    path: "/{id}/roles/{roleName}",
    urlParamKeys: ["id", "roleName"]
  });
  delRole = this.makeRequest({
    method: "DELETE",
    path: "/{id}/roles/{roleName}",
    urlParamKeys: ["id", "roleName"]
  });
  findUsersWithRole = this.makeRequest({
    method: "GET",
    path: "/{id}/roles/{roleName}/users",
    urlParamKeys: ["id", "roleName"]
  });
  /**
   * Service account user
   */
  getServiceAccountUser = this.makeRequest({
    method: "GET",
    path: "/{id}/service-account-user",
    urlParamKeys: ["id"]
  });
  /**
   * Client secret
   */
  generateNewClientSecret = this.makeRequest({
    method: "POST",
    path: "/{id}/client-secret",
    urlParamKeys: ["id"]
  });
  invalidateSecret = this.makeRequest({
    method: "DELETE",
    path: "/{id}/client-secret/rotated",
    urlParamKeys: ["id"]
  });
  generateRegistrationAccessToken = this.makeRequest({
    method: "POST",
    path: "/{id}/registration-access-token",
    urlParamKeys: ["id"]
  });
  getClientSecret = this.makeRequest({
    method: "GET",
    path: "/{id}/client-secret",
    urlParamKeys: ["id"]
  });
  /**
   * Client Scopes
   */
  listDefaultClientScopes = this.makeRequest({
    method: "GET",
    path: "/{id}/default-client-scopes",
    urlParamKeys: ["id"]
  });
  addDefaultClientScope = this.makeRequest({
    method: "PUT",
    path: "/{id}/default-client-scopes/{clientScopeId}",
    urlParamKeys: ["id", "clientScopeId"]
  });
  delDefaultClientScope = this.makeRequest({
    method: "DELETE",
    path: "/{id}/default-client-scopes/{clientScopeId}",
    urlParamKeys: ["id", "clientScopeId"]
  });
  listOptionalClientScopes = this.makeRequest({
    method: "GET",
    path: "/{id}/optional-client-scopes",
    urlParamKeys: ["id"]
  });
  addOptionalClientScope = this.makeRequest({
    method: "PUT",
    path: "/{id}/optional-client-scopes/{clientScopeId}",
    urlParamKeys: ["id", "clientScopeId"]
  });
  delOptionalClientScope = this.makeRequest({
    method: "DELETE",
    path: "/{id}/optional-client-scopes/{clientScopeId}",
    urlParamKeys: ["id", "clientScopeId"]
  });
  /**
   * Protocol Mappers
   */
  addMultipleProtocolMappers = this.makeUpdateRequest({
    method: "POST",
    path: "/{id}/protocol-mappers/add-models",
    urlParamKeys: ["id"]
  });
  addProtocolMapper = this.makeUpdateRequest({
    method: "POST",
    path: "/{id}/protocol-mappers/models",
    urlParamKeys: ["id"]
  });
  listProtocolMappers = this.makeRequest({
    method: "GET",
    path: "/{id}/protocol-mappers/models",
    urlParamKeys: ["id"]
  });
  findProtocolMapperById = this.makeRequest({
    method: "GET",
    path: "/{id}/protocol-mappers/models/{mapperId}",
    urlParamKeys: ["id", "mapperId"],
    catchNotFound: true
  });
  findProtocolMappersByProtocol = this.makeRequest({
    method: "GET",
    path: "/{id}/protocol-mappers/protocol/{protocol}",
    urlParamKeys: ["id", "protocol"],
    catchNotFound: true
  });
  updateProtocolMapper = this.makeUpdateRequest({
    method: "PUT",
    path: "/{id}/protocol-mappers/models/{mapperId}",
    urlParamKeys: ["id", "mapperId"]
  });
  delProtocolMapper = this.makeRequest({
    method: "DELETE",
    path: "/{id}/protocol-mappers/models/{mapperId}",
    urlParamKeys: ["id", "mapperId"]
  });
  /**
   * Scope Mappings
   */
  listScopeMappings = this.makeRequest({
    method: "GET",
    path: "/{id}/scope-mappings",
    urlParamKeys: ["id"]
  });
  addClientScopeMappings = this.makeUpdateRequest({
    method: "POST",
    path: "/{id}/scope-mappings/clients/{client}",
    urlParamKeys: ["id", "client"]
  });
  listClientScopeMappings = this.makeRequest({
    method: "GET",
    path: "/{id}/scope-mappings/clients/{client}",
    urlParamKeys: ["id", "client"]
  });
  listAvailableClientScopeMappings = this.makeRequest({
    method: "GET",
    path: "/{id}/scope-mappings/clients/{client}/available",
    urlParamKeys: ["id", "client"]
  });
  listCompositeClientScopeMappings = this.makeRequest({
    method: "GET",
    path: "/{id}/scope-mappings/clients/{client}/composite",
    urlParamKeys: ["id", "client"]
  });
  delClientScopeMappings = this.makeUpdateRequest({
    method: "DELETE",
    path: "/{id}/scope-mappings/clients/{client}",
    urlParamKeys: ["id", "client"]
  });
  evaluatePermission = this.makeRequest({
    method: "GET",
    path: "/{id}/evaluate-scopes/scope-mappings/{roleContainer}/{type}",
    urlParamKeys: ["id", "roleContainer", "type"],
    queryParamKeys: ["scope"]
  });
  evaluateListProtocolMapper = this.makeRequest({
    method: "GET",
    path: "/{id}/evaluate-scopes/protocol-mappers",
    urlParamKeys: ["id"],
    queryParamKeys: ["scope"]
  });
  evaluateGenerateAccessToken = this.makeRequest({
    method: "GET",
    path: "/{id}/evaluate-scopes/generate-example-access-token",
    urlParamKeys: ["id"],
    queryParamKeys: ["scope", "userId"]
  });
  evaluateGenerateUserInfo = this.makeRequest({
    method: "GET",
    path: "/{id}/evaluate-scopes/generate-example-userinfo",
    urlParamKeys: ["id"],
    queryParamKeys: ["scope", "userId"]
  });
  evaluateGenerateIdToken = this.makeRequest({
    method: "GET",
    path: "/{id}/evaluate-scopes/generate-example-id-token",
    urlParamKeys: ["id"],
    queryParamKeys: ["scope", "userId"]
  });
  addRealmScopeMappings = this.makeUpdateRequest({
    method: "POST",
    path: "/{id}/scope-mappings/realm",
    urlParamKeys: ["id", "client"]
  });
  listRealmScopeMappings = this.makeRequest({
    method: "GET",
    path: "/{id}/scope-mappings/realm",
    urlParamKeys: ["id"]
  });
  listAvailableRealmScopeMappings = this.makeRequest({
    method: "GET",
    path: "/{id}/scope-mappings/realm/available",
    urlParamKeys: ["id"]
  });
  listCompositeRealmScopeMappings = this.makeRequest({
    method: "GET",
    path: "/{id}/scope-mappings/realm/composite",
    urlParamKeys: ["id"]
  });
  delRealmScopeMappings = this.makeUpdateRequest({
    method: "DELETE",
    path: "/{id}/scope-mappings/realm",
    urlParamKeys: ["id"]
  });
  /**
   * Sessions
   */
  listSessions = this.makeRequest({
    method: "GET",
    path: "/{id}/user-sessions",
    urlParamKeys: ["id"]
  });
  listOfflineSessions = this.makeRequest({
    method: "GET",
    path: "/{id}/offline-sessions",
    urlParamKeys: ["id"]
  });
  getSessionCount = this.makeRequest({
    method: "GET",
    path: "/{id}/session-count",
    urlParamKeys: ["id"]
  });
  /**
   * Resource
   */
  getResourceServer = this.makeRequest({
    method: "GET",
    path: "{id}/authz/resource-server",
    urlParamKeys: ["id"]
  });
  updateResourceServer = this.makeUpdateRequest({
    method: "PUT",
    path: "{id}/authz/resource-server",
    urlParamKeys: ["id"]
  });
  listResources = this.makeRequest({
    method: "GET",
    path: "{id}/authz/resource-server/resource",
    urlParamKeys: ["id"]
  });
  createResource = this.makeUpdateRequest({
    method: "POST",
    path: "{id}/authz/resource-server/resource",
    urlParamKeys: ["id"]
  });
  getResource = this.makeRequest({
    method: "GET",
    path: "{id}/authz/resource-server/resource/{resourceId}",
    urlParamKeys: ["id", "resourceId"]
  });
  updateResource = this.makeUpdateRequest({
    method: "PUT",
    path: "/{id}/authz/resource-server/resource/{resourceId}",
    urlParamKeys: ["id", "resourceId"]
  });
  delResource = this.makeRequest({
    method: "DELETE",
    path: "/{id}/authz/resource-server/resource/{resourceId}",
    urlParamKeys: ["id", "resourceId"]
  });
  importResource = this.makeUpdateRequest({
    method: "POST",
    path: "/{id}/authz/resource-server/import",
    urlParamKeys: ["id"]
  });
  exportResource = this.makeRequest({
    method: "GET",
    path: "/{id}/authz/resource-server/settings",
    urlParamKeys: ["id"]
  });
  evaluateResource = this.makeUpdateRequest({
    method: "POST",
    path: "{id}/authz/resource-server/policy/evaluate",
    urlParamKeys: ["id"]
  });
  /**
   * Policy
   */
  listPolicies = this.makeRequest({
    method: "GET",
    path: "{id}/authz/resource-server/policy",
    urlParamKeys: ["id"]
  });
  findPolicyByName = this.makeRequest({
    method: "GET",
    path: "{id}/authz/resource-server/policy/search",
    urlParamKeys: ["id"]
  });
  updatePolicy = this.makeUpdateRequest({
    method: "PUT",
    path: "/{id}/authz/resource-server/policy/{type}/{policyId}",
    urlParamKeys: ["id", "type", "policyId"]
  });
  createPolicy = this.makeUpdateRequest({
    method: "POST",
    path: "/{id}/authz/resource-server/policy/{type}",
    urlParamKeys: ["id", "type"]
  });
  findOnePolicy = this.makeRequest({
    method: "GET",
    path: "/{id}/authz/resource-server/policy/{type}/{policyId}",
    urlParamKeys: ["id", "type", "policyId"],
    catchNotFound: true
  });
  listDependentPolicies = this.makeRequest({
    method: "GET",
    path: "/{id}/authz/resource-server/policy/{policyId}/dependentPolicies",
    urlParamKeys: ["id", "policyId"]
  });
  delPolicy = this.makeRequest({
    method: "DELETE",
    path: "{id}/authz/resource-server/policy/{policyId}",
    urlParamKeys: ["id", "policyId"]
  });
  listPolicyProviders = this.makeRequest({
    method: "GET",
    path: "/{id}/authz/resource-server/policy/providers",
    urlParamKeys: ["id"]
  });
  async createOrUpdatePolicy(payload) {
    const policyFound = await this.findPolicyByName({
      id: payload.id,
      name: payload.policyName
    });
    if (policyFound) {
      await this.updatePolicy({
        id: payload.id,
        policyId: policyFound.id,
        type: payload.policy.type
      }, payload.policy);
      return this.findPolicyByName({
        id: payload.id,
        name: payload.policyName
      });
    } else {
      return this.createPolicy({
        id: payload.id,
        type: payload.policy.type
      }, payload.policy);
    }
  }
  /**
   * Scopes
   */
  listAllScopes = this.makeRequest({
    method: "GET",
    path: "/{id}/authz/resource-server/scope",
    urlParamKeys: ["id"]
  });
  listAllResourcesByScope = this.makeRequest({
    method: "GET",
    path: "/{id}/authz/resource-server/scope/{scopeId}/resources",
    urlParamKeys: ["id", "scopeId"]
  });
  listAllPermissionsByScope = this.makeRequest({
    method: "GET",
    path: "/{id}/authz/resource-server/scope/{scopeId}/permissions",
    urlParamKeys: ["id", "scopeId"]
  });
  listPermissionsByResource = this.makeRequest({
    method: "GET",
    path: "/{id}/authz/resource-server/resource/{resourceId}/permissions",
    urlParamKeys: ["id", "resourceId"]
  });
  listScopesByResource = this.makeRequest({
    method: "GET",
    path: "/{id}/authz/resource-server/resource/{resourceName}/scopes",
    urlParamKeys: ["id", "resourceName"]
  });
  createAuthorizationScope = this.makeUpdateRequest({
    method: "POST",
    path: "{id}/authz/resource-server/scope",
    urlParamKeys: ["id"]
  });
  updateAuthorizationScope = this.makeUpdateRequest({
    method: "PUT",
    path: "/{id}/authz/resource-server/scope/{scopeId}",
    urlParamKeys: ["id", "scopeId"]
  });
  getAuthorizationScope = this.makeRequest({
    method: "GET",
    path: "/{id}/authz/resource-server/scope/{scopeId}",
    urlParamKeys: ["id", "scopeId"]
  });
  delAuthorizationScope = this.makeRequest({
    method: "DELETE",
    path: "/{id}/authz/resource-server/scope/{scopeId}",
    urlParamKeys: ["id", "scopeId"]
  });
  /**
   * Permissions
   */
  findPermissions = this.makeRequest({
    method: "GET",
    path: "{id}/authz/resource-server/permission",
    urlParamKeys: ["id"]
  });
  createPermission = this.makeUpdateRequest({
    method: "POST",
    path: "/{id}/authz/resource-server/permission/{type}",
    urlParamKeys: ["id", "type"]
  });
  updatePermission = this.makeUpdateRequest({
    method: "PUT",
    path: "/{id}/authz/resource-server/permission/{type}/{permissionId}",
    urlParamKeys: ["id", "type", "permissionId"]
  });
  delPermission = this.makeRequest({
    method: "DELETE",
    path: "/{id}/authz/resource-server/permission/{type}/{permissionId}",
    urlParamKeys: ["id", "type", "permissionId"]
  });
  findOnePermission = this.makeRequest({
    method: "GET",
    path: "/{id}/authz/resource-server/permission/{type}/{permissionId}",
    urlParamKeys: ["id", "type", "permissionId"]
  });
  getAssociatedScopes = this.makeRequest({
    method: "GET",
    path: "/{id}/authz/resource-server/policy/{permissionId}/scopes",
    urlParamKeys: ["id", "permissionId"]
  });
  getAssociatedResources = this.makeRequest({
    method: "GET",
    path: "/{id}/authz/resource-server/policy/{permissionId}/resources",
    urlParamKeys: ["id", "permissionId"]
  });
  getAssociatedPolicies = this.makeRequest({
    method: "GET",
    path: "/{id}/authz/resource-server/policy/{permissionId}/associatedPolicies",
    urlParamKeys: ["id", "permissionId"]
  });
  getOfflineSessionCount = this.makeRequest({
    method: "GET",
    path: "/{id}/offline-session-count",
    urlParamKeys: ["id"]
  });
  getInstallationProviders = this.makeRequest({
    method: "GET",
    path: "/{id}/installation/providers/{providerId}",
    urlParamKeys: ["id", "providerId"]
  });
  pushRevocation = this.makeRequest({
    method: "POST",
    path: "/{id}/push-revocation",
    urlParamKeys: ["id"]
  });
  addClusterNode = this.makeRequest({
    method: "POST",
    path: "/{id}/nodes",
    urlParamKeys: ["id"]
  });
  deleteClusterNode = this.makeRequest({
    method: "DELETE",
    path: "/{id}/nodes/{node}",
    urlParamKeys: ["id", "node"]
  });
  testNodesAvailable = this.makeRequest({
    method: "GET",
    path: "/{id}/test-nodes-available",
    urlParamKeys: ["id"]
  });
  getKeyInfo = this.makeRequest({
    method: "GET",
    path: "/{id}/certificates/{attr}",
    urlParamKeys: ["id", "attr"]
  });
  generateKey = this.makeRequest({
    method: "POST",
    path: "/{id}/certificates/{attr}/generate",
    urlParamKeys: ["id", "attr"]
  });
  downloadKey = this.makeUpdateRequest({
    method: "POST",
    path: "/{id}/certificates/{attr}/download",
    urlParamKeys: ["id", "attr"],
    headers: {
      accept: "application/octet-stream"
    }
  });
  generateAndDownloadKey = this.makeUpdateRequest({
    method: "POST",
    path: "/{id}/certificates/{attr}/generate-and-download",
    urlParamKeys: ["id", "attr"],
    headers: {
      accept: "application/octet-stream"
    }
  });
  uploadKey = this.makeUpdateRequest({
    method: "POST",
    path: "/{id}/certificates/{attr}/upload",
    urlParamKeys: ["id", "attr"]
  });
  uploadCertificate = this.makeUpdateRequest({
    method: "POST",
    path: "/{id}/certificates/{attr}/upload-certificate",
    urlParamKeys: ["id", "attr"]
  });
  updateFineGrainPermission = this.makeUpdateRequest({
    method: "PUT",
    path: "/{id}/management/permissions",
    urlParamKeys: ["id"]
  });
  listFineGrainPermissions = this.makeRequest({
    method: "GET",
    path: "/{id}/management/permissions",
    urlParamKeys: ["id"]
  });
  constructor(client) {
    super(client, {
      path: "/admin/realms/{realm}/clients",
      getUrlParams: () => ({
        realm: client.realmName
      }),
      getBaseUrl: () => client.baseUrl
    });
  }
  /**
   * Find single protocol mapper by name.
   */
  async findProtocolMapperByName(payload) {
    const allProtocolMappers = await this.listProtocolMappers({
      id: payload.id,
      ...(payload.realm ? {
        realm: payload.realm
      } : {})
    });
    return allProtocolMappers.find(mapper => mapper.name === payload.name);
  }
}