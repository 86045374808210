import Resource from "./resource.js";
export class Components extends Resource {
  /**
   * components
   * https://www.keycloak.org/docs-api/11.0/rest-api/#_component_resource
   */
  find = this.makeRequest({
    method: "GET"
  });
  create = this.makeRequest({
    method: "POST",
    returnResourceIdInLocationHeader: {
      field: "id"
    }
  });
  findOne = this.makeRequest({
    method: "GET",
    path: "/{id}",
    urlParamKeys: ["id"],
    catchNotFound: true
  });
  update = this.makeUpdateRequest({
    method: "PUT",
    path: "/{id}",
    urlParamKeys: ["id"]
  });
  del = this.makeRequest({
    method: "DELETE",
    path: "/{id}",
    urlParamKeys: ["id"]
  });
  listSubComponents = this.makeRequest({
    method: "GET",
    path: "/{id}/sub-component-types",
    urlParamKeys: ["id"],
    queryParamKeys: ["type"]
  });
  constructor(client) {
    super(client, {
      path: "/admin/realms/{realm}/components",
      getUrlParams: () => ({
        realm: client.realmName
      }),
      getBaseUrl: () => client.baseUrl
    });
  }
}